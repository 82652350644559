import React from "react";
import { Helmet } from "react-helmet";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Header from "../components/sections/index/header";
import Features from "../components/sections/index/features";
import PushPast from "../components/sections/index/pushPast";
import Footer from "../components/sections/footer";

import theme from "../components/theme/muiTheme";
import { ThemeProvider } from "@material-ui/core";

const IndexPage: React.FC = (): React.ReactElement => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Roboto:wght@300&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      <Layout>
        <SEO title="Home" />
        <Navigation />
        <Header />
        <Features />
        <PushPast />
        <Footer />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;
