import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { Accent } from "../../global";
import headerVideo from "../../../videos/HeaderVideo.mp4";
import { TheCourseURL } from "../../common/navigation/courses";

const About: React.FC = () => {
  return (
    <div id="header">
      <Container>
        <BackgroundVideo autoPlay loop muted playsInline id="header-video" src={headerVideo} />
        <Gradient />
        <Copy>
          <Typography variant="h1">
            <b>
              <Accent>OPTIMISE</Accent>
            </b>
          </Typography>
          <Typography variant="h1">
            <b>
              <Accent> YOUR BRAIN </Accent>
            </b>
          </Typography>
          <Typography variant="h3" align="right">
            FOR ELITE SPORT
          </Typography>
        </Copy>
        <Course>
          <Typography variant="h6">Ako Maps Powered Course</Typography>
          <Flex>
            <a href={TheCourseURL}>
              <Typography variant="h3" style={{ textDecoration: "underline", textDecorationColor: "#faed25" }}>
                The Course
              </Typography>
            </a>
          </Flex>
        </Course>
      </Container>
    </div>
  );
};

export default About;

const Container = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  background-color: black;
  min-height: 340px;
`;
const Copy = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  max-width: 1100px;
  margin-left: 128px;
  margin-right: 64px;
  color: white;
  height: 80vh;
  position: relative;
  justify-content: center;
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 16px;
    margin-right: 16px;
    padding: 16px;
  }
  @media (max-height: ${props => props.theme.screen.sm}) {
    height: 100vh;
  }
`;

const Course = styled.div`
  position: absolute;
  bottom: 16px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  left: calc(50% - 200px);
  color: white;
  text-align: center;
`;

const Flex = styled.div`
  display: flex;
  padding-bottom: 16px;
`;

const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(0, 0, 0, 1), 65%, rgba(0, 0, 0, 0));
`;
const BackgroundVideo = styled.video`
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: auto;
  right: auto;
  transition: all 2400ms cubic-bezier(0.8, 0, 0.145, 1);
  transition-delay: 1800ms;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  @media (max-width: ${props => props.theme.screen.sm}) {
    top: 50%;
  }
`;
