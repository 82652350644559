import React from "react";
import styled from "styled-components";

import { Wrapper as _Wrapper, Container, Accent } from "../../global";
import { Typography } from "@material-ui/core";

import backgroundImage from "../../../images/index/pushPast_BG.jpg";

const Features: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <BackgroundImage src={backgroundImage} />
        <TextContainer>
          <Typography variant="h2" align="left" style={{ padding: "0 0 32px" }}>
            <b>Push Past Your Limits</b>
          </Typography>
          <Typography variant="inherit" align="left">
            <Accent>
              Developed by Formula 1 neuroscientist, Dr. Kerry Spackman has created a world leading elite performance
              course.
            </Accent>
          </Typography>
          <br></br>
          <Typography variant="inherit" align="left">
            The course is packed full of practical sports science to teach you how to overcome conscious limitations,
            improve reaction times and practice like a world class champion. Learn how your brain operates at the
            highest level of motor performance and psychological limits. Push past your limits and join the ranks of
            world class champions.
          </Typography>
        </TextContainer>
      </Container>
    </Wrapper>
  );
};

export default Features;

const Wrapper = styled(_Wrapper)`
  background-color: black;
`;
const TextContainer = styled.div`
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 60%;
  justify-content: center;
  padding: 32px 16px 32px;
  line-height: 28px;
  letter-spacing: 2px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 100%;
  }
`;

const BackgroundImage = styled.img`
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: hidden;
  @media (max-width: ${props => props.theme.screen.sm}) {
    top: 50%;
  }
`;
