import React from "react";
import styled from "styled-components";

import { Wrapper, Container as _Container } from "../../global";
import { Grid, Typography } from "@material-ui/core";

import featureImage_01 from "../../../images/index/feature1.png";
import featureImage_02 from "../../../images/index/feature2.png";
import featureImage_03 from "../../../images/index/feature3.png";
import featureImage_04 from "../../../images/index/feature4.png";

const Features: React.FC = () => {
  return (
    <Wrapper>
      <Container>
        <Grid container direction="row">
          <Item item>
            <img src={featureImage_02} />
            <Typography variant="h4" align="center">
              MOTOR FUNCTION
            </Typography>
          </Item>
          <Item item>
            <img src={featureImage_03} />
            <Typography variant="h4" align="center">
              PERCEPTION
            </Typography>
          </Item>
          <Item item>
            <img src={featureImage_04} />
            <Typography variant="h4" align="center">
              ACTUATION
            </Typography>
          </Item>
          <Item item>
            <img src={featureImage_01} />
            <Typography variant="h4" align="center">
              GET IN THE ZONE
            </Typography>
          </Item>
        </Grid>
      </Container>
    </Wrapper>
  );
};

export default Features;

const Container = styled(_Container)`
  padding-top: 128px;
`;
const Item = styled(Grid)`
  margin: auto;
  > img {
    width: 200px;
  }
`;
